import { useContext } from 'react';

import ErrorBoundary from '@components/ErrorBoundary';
import DiscoverMoreChips from '@components/discover-more/DiscoverMoreChips';
import HeaderWithCTAI from '@components/header-ai';
import HeaderWithCTA from '@components/header-with-cta';
import PricingPlan from '@components/pricing-plan';
import PricingPlanAI from '@components/pricing-plan/ai';
import SuperbVideoCTA from '@components/superb-videos-cta';

import TutorialVideo from '@components/tutorial-video/TutorialVideo';
import WorkflowV2 from '@components/workflow-v2';
import { BaseAppType } from '@constants/common';
import { Faq } from '@constants/makepage';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import FAQ from '@invideoio/web-shared/components/Faq';
import Footer from '@invideoio/web-shared/components/Footer';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import FooterConfig from '@invideoio/web-shared/constants/footer';
import DiscoverBlogs from '@invideoio/web-shared/sections/DiscoverBlogs';
import AppLayout from '@layouts/AppLayout';
import { StateContext } from '@store/store';
import dynamic from 'next/dynamic';
import { MakePageV3ResponseType } from 'src/types';
import StudioBanner from '@components/banner/studio-banner';

const Templates = dynamic(
  () =>
    import(
      /* webpackChunkName: "Templates" */
      '@components/templates'
    ),
);

const FixedBanner = dynamic(
  () =>
    import(
      /* webpackChunkName: "FixedBanner" */
      '@invideoio/web-shared/components/FixedBanner'
    ),
);

const TestimonialMake = dynamic(
  () =>
    import(
      /* webpackChunkName: "TestimonialMake" */
      '@components/testimonials/TestimonialMake'
    ),
);

const HeroSectionV2 = dynamic(
  () =>
    import(
      /* webpackChunkName: "HeroSectionV2" */
      '@components/hero-section-v2'
    ),
);

const GuideSection = dynamic(
  () =>
    import(
      /* webpackChunkName: "GuideSection" */
      '@components/guide-section'
    ),
);

const Achievements = dynamic(
  () =>
    import(
      /* webpackChunkName: "Achievements" */
      '@components/achievements'
    ),
);

const FeaturesCard = dynamic(
  () =>
    import(
      /* webpackChunkName: "FeaturesCard" */
      '@components/features-card'
    ),
);

interface MakePageContainerV3Props {
  makePageState: MakePageV3ResponseType;
}

function MakePageContainerV3(props: MakePageContainerV3Props) {
  const globalState = useContext(StateContext);
  const { makePageState } = props;
  const { platform, os, browser } = globalState;

  const {
    feature,
    featureCard3Desktop,
    featureCard3Mobile,
    MakeWorkflow,
    BaseAppType: baseAppType = BaseAppType.STUDIO,
  } = makePageState;

  const showFeatureCard =
    feature?.card1 &&
    feature?.card2 &&
    (feature?.sectionHead || feature?.title) &&
    featureCard3Desktop &&
    featureCard3Mobile;

  const { guideCards, guideHeading } = makePageState;
  const showGuideSection = guideCards && guideHeading;

  const {
    templateSection,
    templateSlide_1,
    templateSlide_2,
    templateSlide_3,
    templateSlide_4,
  } = makePageState;
  const showTemplateSection =
    templateSection?.heading &&
    templateSection?.buttonText &&
    templateSection?.CTAUrl &&
    templateSlide_1 &&
    templateSlide_2 &&
    templateSlide_3 &&
    templateSlide_4;

  return (
    <AppLayout platform={platform}>
      {baseAppType === BaseAppType.AI ? (
        <>
          <HeaderWithCTAI />
        </>
      ) : (
        <>
          <StudioBanner browser={browser} />
          {/* <FixedBanner browser={browser} /> */}
          <HeaderWithCTA />
        </>
      )}
      <div
        className={ivclass(
          'iv-max-w-[100vw] iv-overflow-hidden iv-pb-60 iv-mb-[-100px]',
        )}
      >
        <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
          <ErrorBoundary>
            <HeroSectionV2
              browser={browser}
              heroSection={makePageState.heroSection}
            />
          </ErrorBoundary>
        </div>
      </div>
      <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
        <Achievements />
        {showTemplateSection && (
          <ErrorBoundary>
            <Templates
              layoutV3
              platform={platform}
              slide_1={templateSlide_1}
              slide_2={templateSlide_2}
              slide_3={templateSlide_3}
              slide_4={templateSlide_4}
              heading={templateSection.heading}
              CTAUrl={templateSection.CTAUrl}
              buttonText={templateSection.buttonText}
            />
          </ErrorBoundary>
        )}

        {MakeWorkflow && (
          <ErrorBoundary>
            <WorkflowV2
              platform={platform}
              browser={browser}
              makeWorkFlow={MakeWorkflow}
            />
          </ErrorBoundary>
        )}

        {makePageState.ScrollCards && (
          <ErrorBoundary>
            <TestimonialMake
              data={makePageState.ScrollCards}
              browser={browser}
              platform={platform}
            />
          </ErrorBoundary>
        )}

        {showFeatureCard && (
          <ErrorBoundary>
            <FeaturesCard
              platform={platform as PlatformType}
              browser={browser}
              sectionHead={feature.sectionHead}
              title={feature.title}
              card1={feature.card1}
              card2={feature.card2}
              card3Desktop={featureCard3Desktop}
              card3Mobile={featureCard3Mobile}
              card4={feature.card4}
              layoutV3
              isAiPage={baseAppType === BaseAppType.AI}
            />
          </ErrorBoundary>
        )}
      </div>
      {makePageState.ShowPricing && (
        <div className={ivclass('sm:iv-mt-20')}>
          <div className={ivclass('xl:iv-container xl:iv-mx-auto')}>
            <ErrorBoundary>
              {baseAppType === BaseAppType.AI ? (
                <PricingPlanAI
                  theme="light"
                  platform={platform as PlatformType}
                  pricingTitle={{
                    plainHeading: 'The right plans,',
                    highlightedHeading: 'for the right price',
                  }}
                />
              ) : (
                <PricingPlan
                  theme="light"
                  platform={platform as PlatformType}
                  pricingTitle={{
                    plainHeading: 'The right plans,',
                    highlightedHeading: 'for the right price',
                  }}
                />
              )}
            </ErrorBoundary>
          </div>
        </div>
      )}
      {showGuideSection && (
        <ErrorBoundary>
          <GuideSection
            platform={platform}
            cards={makePageState.guideCards}
            heading={makePageState.guideHeading}
            isLayoutV3
          />
        </ErrorBoundary>
      )}
      {makePageState.FaqItems && makePageState.FAQSection && (
        <ErrorBoundary>
          <FAQ
            platform={PlatformType.Desktop}
            title={{
              ...makePageState.FAQSection.title,
              highlightedHeadingClass:
                'iv-block iv-title-gradient iv-leading-tight',
            }}
            items={makePageState.FaqItems}
            icons={Faq.icons}
          />
        </ErrorBoundary>
      )}
      {makePageState.tutorialVideo && (
        <ErrorBoundary>
          <TutorialVideo
            videoData={makePageState.tutorialVideo}
            browser={browser}
            platform={platform}
          />
        </ErrorBoundary>
      )}
      {makePageState.discorverMore && (
        <ErrorBoundary>
          <DiscoverMoreChips discoverMore={makePageState.discorverMore} />
        </ErrorBoundary>
      )}
      {makePageState.invideo_blogs && makePageState.blogs && (
        <ErrorBoundary>
          <DiscoverBlogs
            blogs={makePageState.invideo_blogs}
            blogHeading={makePageState.blogs}
            platform={platform}
            browser={browser}
          />
        </ErrorBoundary>
      )}
      {makePageState.superbVideos && (
        <ErrorBoundary>
          <SuperbVideoCTA
            heading={makePageState.superbVideos.heading}
            detail={makePageState.superbVideos.detail}
            buttonText={makePageState.superbVideos.buttonText}
            CTAUrl={makePageState.superbVideos.CTAUrl}
            layoutV3
          />
        </ErrorBoundary>
      )}
      <Footer
        os={os}
        footer={
          baseAppType === BaseAppType.AI
            ? FooterConfig.FOOTER_AI
            : FooterConfig.FOOTER
        }
        platform={platform}
        socialLinks={globalState.socialLinks}
      />
    </AppLayout>
  );
}

export default MakePageContainerV3;
